@import '~foundation-sites/scss/util/util';
@import "src/styles/settings/variables";

.container {
  background-repeat: no-repeat;
  background-position: center bottom;
  background-image: url('./assets/backgrounds/small-down.png');
  background-size: 180%;
  @include breakpoint(retina) {
    background-image: url('./assets/backgrounds/small-down@2x.png');
  }
  @include breakpoint(medium) {
    overflow-x: hidden;
    background-size: 150%;
    background-image: url('./assets/backgrounds/medium-down.png');
    @include breakpoint(retina) {
      background-image: url('./assets/backgrounds/medium-down@2x.png');
    }
  }
  @include breakpoint(large) {
    background-image: url('./assets/backgrounds/large-down.png');
    background-size: contain;
    @include breakpoint(retina) {
      background-image: url('./assets/backgrounds/large-down@2x.png');
    }
  }
}

.container.containerFinal {
  overflow: hidden;
  @include breakpoint(medium) {
    min-height: 1024px;
  }
  .headlineContainer {
    padding-top: 20px;
    @include breakpoint(medium) {
      padding-top: 49px;
    }
    @include breakpoint(large) {
      padding-top: 39px;
    }
  }
  .headline {
    font-size: 64px;
    @include breakpoint(medium) {
      font-size: 104px;
    }
    @include breakpoint(large) {
      font-size: 128px;
    }
  }
  .composition {
    position: relative;
    @include breakpoint(small only) {
      img {
        width: 100%;
      }
    }
    @include breakpoint(medium only) {
      position: absolute;
      width: 510px;
      height: 510px;
      top: 34px;
      right: -88px;
    }
    @media only screen and (min-width: 700px) and (max-width: 800px) {
      width: 450px;
      height: 450px;
    }
    @media only screen and (min-width: 870px) and (max-width: 900px) {
      right: 0;
    }
    @media only screen and (min-width: 901px) and (max-width: 1024px) {
      right: 0;
      width: 600px;
      height: 600px;
    }
    @media only screen and (min-width: 1025px) and (max-width: 1199px) {
      right: 0;
      width: 600px;
      height: 600px;
    }
    @include breakpoint(large) {
      position: absolute;
      right: -155px;
      top: 55px;
      width: 733px;
      height: 733px;
      img {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
}

.preHeadlineContainer {
  margin-top: 32px;
  @include breakpoint(small only) {
    text-align: center;
  }
  @include breakpoint(medium) {
    margin-top: 80px;
  }
  @include breakpoint(large) {
    margin-top: 96px;
  }
}

.tag {
  background: rgb(201,34,34);
  background: linear-gradient(180deg, rgba(201,34,34,1) 0%, rgba(150,9,9,1) 100%);
  display: inline-block;
  padding: 9px 0 3px;
  position: relative;
  @include breakpoint(small only) {
    left: 10px;
  }
  @include breakpoint(large) {
    margin-left: 18px;
  }
  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    z-index: 0;
    width: 20px;
    height: 100%;
    background: linear-gradient(180deg, rgba(201,34,34,1) 0%, rgba(150,9,9,1) 100%);
  }
  &:before {
    left: -14px;
    transform: skew(-12deg);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    @include breakpoint(medium) {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  }
  &:after {
    right: -14px;
    transform: skew(-12deg);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    @include breakpoint(medium) {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
  span {
    font-family: $font-secondary;
    display: inline-block;
    color: $color-white;
    font-weight: 500;
    line-height: 110%;
    text-transform: uppercase;
    position: relative;
    z-index: 1;
    font-size: 28px;
    @include breakpoint(medium) {
      font-size: 32px;
    }
    @include breakpoint(large) {
      font-size: 40px;
    }
  }
}

// Результаты голосования
.surveyResultsContainer {
  @include breakpoint(small only) {
    margin-top: 20px;
    padding-bottom: 70px;
  }
  @include breakpoint(medium) {
    margin-top: 188px;
  }
  @include breakpoint(medium only) {
    margin-left: 47px;
  }
  @include breakpoint(large) {
    margin-top: 120px;
  }
}
.surveyResults {
  max-width: 300px;
  position: relative;
  @include breakpoint(small only) {
    margin: 0 auto;
    left: 0;
    right: 0;
  }
  @include breakpoint(medium) {
    max-width: 600px;
  }
  @include breakpoint(large) {
    max-width: 500px;
  }
  &:before {
    content: "";
    position: absolute;
    left: -60px;
    top: -60px;
    z-index: 1;
    @include breakpoint(small only) {
      background: url('./assets/coins/coins-small.png') no-repeat;
      width: 419px;
      height: 270px;
      @include breakpoint(retina) {
        background-image: url('./assets/coins/coins-small@2x.png');
        background-size: cover;
      }
    }
    @include breakpoint(medium only) {
      background: url('./assets/coins/coins-medium.png') no-repeat;
      width: 727px;
      height: 358px;
      left: -46px;
      top: -90px;
      @include breakpoint(retina) {
        background-image: url('./assets/coins/coins-medium@2x.png');
        background-size: cover;
      }
    }
    @include breakpoint(large) {
      background: url('./assets/coins/coins-large.png') no-repeat;
      left: -95px;
      top: -95px;
      width: 689px;
      height: 444px;
      @include breakpoint(retina) {
        background-image: url('./assets/coins/coins-large@2x.png');
        background-size: cover;
      }
    }
  }
}
.results {
  position: relative;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(4px);
  border-radius: 16px;
  padding: 20px;
  @include breakpoint(medium only) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  @include breakpoint(medium) {
    padding: 24px;
    border-radius: 20px;
  }
  @include breakpoint(large) {
    padding: 32px;
    border-radius: 24px;
  }
}
.variantPercentage {
  font-family: $font-secondary;
  color: $color-main-orange;
  font-weight: 500;
  line-height: 110%;
  text-transform: uppercase;
  font-size: 60px;
  @include breakpoint(medium) {
    font-size: 90px;
  }
  @include breakpoint(medium only) {
    align-self: center;
  }
  @include breakpoint(large) {
    font-size: 100px;
  }
}
.variantTitle {
  font-family: $font-secondary;
  color: $color-black;
  font-weight: 500;
  line-height: 120%;
  text-transform: uppercase;
  font-size: 24px;
  @include breakpoint(medium) {
    font-size: 36px;
  }
  @include breakpoint(medium only) {
    padding-left: 48px;
  }
  @include breakpoint(large) {
    font-size: 40px;
  }
}
// Конец результаты голосования

.logosContainer {
  border-bottom: 1px solid rgba(255, 255, 255, 0.20);
  padding: 20px 0;
  @include breakpoint(medium) {
    padding: 32px 0;
  }
  @include breakpoint(large) {
    padding: 24px 0;
  }
}

.logos {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  @include breakpoint(medium down) {
    position: relative;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  a {
    display: block;
  }
}

.winlineLogo {
  img {
    @include breakpoint(small only) {
      height: 28px;
    }
    @include breakpoint(medium only) {
      height: 40px;
    }
  }
}

.spartakLogo {
  img {
    @include breakpoint(small only) {
      height: 48px;
    }
    @include breakpoint(medium only) {
      height: 72px;
    }
  }
}

.headlineContainer {
  padding-top: 32px;
  @include breakpoint(medium) {
    padding-top: 80px;
  }
}

.headline {
  font-size: 40px;
  line-height: 110%;
  @include breakpoint(small only) {
    text-align: center;
  }
  @include breakpoint(medium) {
    font-size: 56px;
  }
  @include breakpoint(large) {
    font-size: 80px;
  }
}

.headlineAccent {
  position: relative;
  color: $color-white;
  font-size: 64px;
  line-height: 100%;
  @include breakpoint(small only) {
    text-align: center;
    margin-top: 6px;
  }
  @include breakpoint(medium) {
    font-size: 88px;
    line-height: 110%;
    margin-top: 8px;
  }
  @include breakpoint(large) {
    font-size: 114px;
    line-height: 110%;
  }
}

// Главное изображение
.mainComposition {
  text-align: center;
  @include breakpoint(small only) {
    max-width: 400px;
    position: relative;
    left: 0;
    right: 0;
    margin: 5px auto 0;
    img {
      position: relative;
      right: 9px;
    }
  }
  @include breakpoint(medium) {
    background-image: url('./assets/main-composition.png');
    background-size: cover;
    position: absolute;
    top: 47px;
    right: -20px;
    width: 415px;
    height: 415px;
  }
  @include breakpoint(large) {
    top: 40px;
    right: -40px;
    width: 646px;
    height: 646px;
  }
  @include breakpoint(retina) {
    @include breakpoint(medium) {
      background-image: url('./assets/main-composition@2x.png');
    }
  }
}

// Кнопки

.buttonContainer {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  gap: 32px;
  margin-bottom: 80px;
  @include breakpoint(medium) {
    gap: 40px;
    margin-top: 62px;
    margin-bottom: 140px;
  }
  @include breakpoint(large) {
    margin-top: 88px;
    margin-bottom: 180px;
  }
}

.button {
  background-color: green;

  @include breakpoint(small only) {
    display: block;
    button {
      width: 100%;
    }
  }
}

.buttonLabel {
  color: $color-white;
  @include text-style-whitney-t-18-m;
  text-align: center;
  @include breakpoint(small only) {
    align-self: center;
  }
  @include breakpoint(medium) {
    text-align: left;
    font-size: 20px;
    line-height: 130%;
  }
  @include breakpoint(large) {
    font-size: 24px;
  }
}
